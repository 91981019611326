import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";
import Swal from "sweetalert2";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
import Login from "../pages/Login.vue";
import Forbidden from "../pages/Forbidden.vue";
import NotFound from "../pages/NotFound.vue";

import Home from "../pages/front-end/Home.vue";
import Formulir1 from "../pages/front-end/Formulir1.vue";
import Formulir2 from "../pages/front-end/Formulir2.vue";
import Formulir3 from "../pages/front-end/Formulir3.vue";
import Formulir4 from "../pages/front-end/Formulir4.vue";
import Formulir5 from "../pages/front-end/Formulir5.vue";

import BangunanList from "../pages/bangunan-irigasi/Index.vue";
import BangunanDetail from "../pages/bangunan-irigasi/Detail.vue";
import BangunanFoto from "../pages/bangunan-irigasi/Foto.vue";

import SaluranList from "../pages/saluran/Index.vue";
import SaluranDetail from "../pages/saluran/Detail.vue";
import SaluranFoto from "../pages/saluran/Foto.vue";

import JalanList from "../pages/jalan/Index.vue";
import JalanDetail from "../pages/jalan/Detail.vue";
import JalanFoto from "../pages/jalan/Foto.vue";

import GalianList from "../pages/galian/Index.vue";
import GalianDetail from "../pages/galian/Detail.vue";
import GalianFoto from "../pages/galian/Foto.vue";

import TanamanList from "../pages/tanaman/Index.vue";
import TanamanDetail from "../pages/tanaman/Detail.vue";
import TanamanFoto from "../pages/tanaman/Foto.vue";

import Pengguna from "../pages/pengguna/Index.vue";
import PenggunaForm from "../pages/pengguna/Form.vue";
import PenggunaEdit from "../pages/pengguna/Edit.vue";
import PenggunaPassword from "../pages/pengguna/Password.vue";

import DaerahIrigasi from "../pages/master/daerah-irigasi/Index.vue";
import MasterBangunan from "../pages/master/bangunan/Index.vue";
import MasterSaluran from "../pages/master/saluran/Index.vue";
import MasterRuas from "../pages/master/ruas/Index.vue";

const routes = [
  { path: "/", component: Home },
  { path: "*", component: Home },
  { path: "/dashboard/", component: Dashboard },
  { path: "/forbidden/", component: Forbidden },
  { path: "/not-found/", component: NotFound },
  { path: "/login/", component: Login },
  { path: "/formulir-1/", component: Formulir1 },
  { path: "/formulir-2/", component: Formulir2 },
  { path: "/formulir-3/", component: Formulir3 },
  { path: "/formulir-4/", component: Formulir4 },
  { path: "/formulir-5/", component: Formulir5 },
  { path: "/bangunan-irigasi/", component: BangunanList },
  { path: "/bangunan-irigasi/detail/:id", component: BangunanDetail },
  { path: "/bangunan-irigasi/foto/:id", component: BangunanFoto },
  { path: "/saluran/", component: SaluranList },
  { path: "/saluran/detail/:id", component: SaluranDetail },
  { path: "/saluran/foto/:id", component: SaluranFoto },
  { path: "/jalan-inspeksi/", component: JalanList },
  { path: "/jalan-inspeksi/detail/:id", component: JalanDetail },
  { path: "/jalan-inspeksi/foto/:id", component: JalanFoto },
  { path: "/galian-endapan/", component: GalianList },
  { path: "/galian-endapan/detail/:id", component: GalianDetail },
  { path: "/galian-endapan/foto/:id", component: GalianFoto },
  { path: "/tanaman/", component: TanamanList },
  { path: "/tanaman/detail/:id", component: TanamanDetail },
  { path: "/tanaman/foto/:id", component: TanamanFoto },
  { path: "/pengguna/", component: Pengguna, meta: { requiredLevel: "admin" } },
  {
    path: "/pengguna/create",
    component: PenggunaForm,
    meta: { requiredLevel: "admin" },
  },
  { path: "/pengguna/update/:id", component: PenggunaEdit },
  { path: "/pengguna/password/:id", component: PenggunaPassword },
  { path: "/master-daerah-irigasi", component: DaerahIrigasi },
  { path: "/master-bangunan", component: MasterBangunan },
  { path: "/master-saluran", component: MasterSaluran },
  { path: "/master-ruas", component: MasterRuas },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("surveillanceData");
  const userAceess = JSON.parse(isLoggedIn);
  const userLevel = userAceess ? userAceess.level : null;

  const requiredLevel = to.meta.requiredLevel;

  if (
    !isLoggedIn &&
    to.path !== "/login" &&
    to.path !== "/" &&
    to.path !== "/formulir-1" &&
    to.path !== "/formulir-2" &&
    to.path !== "/formulir-3" &&
    to.path !== "/formulir-4" &&
    to.path !== "/formulir-5"
  ) {
    next("/");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden");
    } else {
      next();
    }
  } else {
    next();
  }

  const userData = localStorage.getItem("surveillanceData");
  if (userData) {
    const user = JSON.parse(userData);

    axios
      .get("/v1/auth/status/", {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("surveillanceData");
            if (to.path !== "/") {
              next("/");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;
