<template>
  <div id="layoutSidenav_nav">
    <nav class="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
      <div class="sb-sidenav-menu">
        <div class="nav">
          <div class="sb-sidenav-menu-heading">Menu</div>
          <router-link
            class="nav-link"
            to="/dashboard"
            :class="{
              ' active': link == 'dashboard',
            }"
          >
            <div class="sb-nav-link-icon">
              <font-awesome-icon icon="home" />
            </div>
            Dashboard
          </router-link>
          <router-link
            class="nav-link"
            to="/bangunan-irigasi"
            :class="{
              ' active': link == 'bangunan-irigasi',
            }"
          >
            <div class="sb-nav-link-icon">
              <i class="icon-bendungan"></i>
            </div>
            Bangunan Irigasi
          </router-link>
          <router-link
            class="nav-link"
            to="/saluran"
            :class="{
              ' active': link == 'saluran',
            }"
          >
            <div class="sb-nav-link-icon">
              <i class="icon-saluran"></i>
            </div>
            Saluran
          </router-link>
          <router-link
            class="nav-link"
            to="/jalan-inspeksi"
            :class="{
              ' active': link == 'jalan-inspeksi',
            }"
          >
            <div class="sb-nav-link-icon">
              <i class="icon-jalan"></i>
            </div>
            Jalan Inspeksi
          </router-link>
          <router-link
            class="nav-link"
            to="/galian-endapan"
            :class="{
              ' active': link == 'galian-endapan',
            }"
          >
            <div class="sb-nav-link-icon">
              <i class="icon-galian"></i>
            </div>
            Galian Endapan
          </router-link>
          <router-link
            class="nav-link"
            to="/tanaman"
            :class="{
              ' active': link == 'tanaman',
            }"
          >
            <div class="sb-nav-link-icon">
              <i class="icon-galian"></i>
            </div>
            Tanaman
          </router-link>
          <a
            class="nav-link collapsed"
            href="javascript:;"
            data-bs-toggle="collapse"
            data-bs-target="#pagesCollapseMaster"
            aria-expanded="true"
            aria-controls="pagesCollapseMaster"
          >
            <div class="sb-nav-link-icon">
              <font-awesome-icon icon="database" />
            </div>
            Master
            <div class="sb-sidenav-collapse-arrow">
              <font-awesome-icon icon="angle-down" />
            </div>
          </a>
          <div
            class="collapse"
            :class="{ show: isMasterMenuOpen }"
            id="pagesCollapseMaster"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordionPages"
          >
            <nav class="sb-sidenav-menu-nested nav">
              <router-link
                class="nav-link"
                to="/master-daerah-irigasi"
                :class="{
                  ' active': link == 'master-daerah-irigasi',
                }"
              >
                Daerah Irigasi
              </router-link>
              <router-link
                class="nav-link"
                to="/master-bangunan"
                :class="{
                  ' active': link == 'master-bangunan',
                }"
              >
                Bangunan
              </router-link>
              <router-link
                class="nav-link"
                to="/master-saluran"
                :class="{
                  ' active': link == 'master-saluran',
                }"
              >
                Saluran
              </router-link>
              <router-link
                class="nav-link"
                to="/master-ruas"
                :class="{
                  ' active': link == 'master-ruas',
                }"
              >
                Ruas
              </router-link>
            </nav>
          </div>
          <router-link
            v-if="userLevel == 'admin'"
            class="nav-link"
            to="/pengguna"
            :class="{
              ' active': link == 'pengguna',
            }"
          >
            <div class="sb-nav-link-icon">
              <font-awesome-icon icon="users" />
            </div>
            Pengguna
          </router-link>
        </div>
      </div>
      <div class="sb-sidenav-footer">
        <div class="small">Login as :</div>
        <span>{{ userEmail }}</span>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "SideBar",
  data() {
    return {
      link: "dashboard",
      isMasterMenuOpen: false,
      userName: "Administrator",
      userEmail: "admin@admin.com",
      userLevel: "user",
    };
  },
  created() {
    const user = localStorage.getItem("surveillanceData");
    if (user) {
      this.user = JSON.parse(user);
      this.userName = this.user.nama;
      this.userEmail = this.user.email;
      this.userLevel = this.user.level;
    }
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        const parts = newValue.split("/");
        const link = parts[1];
        this.link = link;
        this.isMasterMenuOpen =
          this.link === "master-daerah-irigasi" ||
          this.link === "master-bangunan" ||
          this.link === "master-saluran" ||
          this.link === "master-ruas";
      },
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "IrigationIcon";
  src: url("../assets/fonts/icomoon.woff") format("woff");
  /* tambahkan format lain jika diperlukan */
}

.icon-saluran:before {
  font-family: "IrigationIcon";
  content: "\e900";
  font-style: normal;
  font-size: 16px;
}
.icon-bendungan:before {
  font-family: "IrigationIcon";
  content: "\e90e";
  font-style: normal;
  font-size: 16px;
}
.icon-galian:before {
  font-family: "IrigationIcon";
  content: "\e913";
  font-style: normal;
  font-size: 16px;
}
.icon-jalan:before {
  font-family: "IrigationIcon";
  content: "\e91e";
  font-style: normal;
  font-size: 16px;
}
.sb-sidenav-menu::-webkit-scrollbar {
  display: none;
}
</style>

