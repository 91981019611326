<template>
  <div style="background-color: aliceblue">
    <Header></Header>
    <div class="p-4">
      <div
        class="row align-items-center justify-content-center"
        style="margin-top: -180px"
      >
        <div class="col-md-8 bg-white p-4 rounded-5 shadow-lg box">
          <h4 class="mb-4 text-center">Pilih Formulir</h4>
          <div class="row mb-3">
            <div class="col-md-4">
              <label>Nama Surveyor</label>
            </div>
            <div class="col-md-8">
              <input
                v-model="form.nama_surveyor"
                type="text"
                class="form-control"
                :class="{
                  ' is-invalid': formValidate.nama_surveyor === false,
                  ' is-valid': formValidate.nama_surveyor === true,
                }"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-4">
              <label>Daerah Irigasi</label>
            </div>
            <div class="col-md-8">
              <select
                v-model="form.daerah_irigasi"
                class="form-select"
                :class="{
                  ' is-invalid': formValidate.daerah_irigasi === false,
                  ' is-valid': formValidate.daerah_irigasi === true,
                }"
                v-on:change="handleChange($event)"
              >
                <option value="">Silahkan Pilih</option>
                <option
                  v-for="daerah_irigasi in daerahIrigasi"
                  :key="daerah_irigasi.id_mst_daerah_irigasi"
                  :value="daerah_irigasi.id_mst_daerah_irigasi"
                  :data-key="daerah_irigasi.nama_daerah_irigasi"
                >
                  {{ daerah_irigasi.nama_daerah_irigasi }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-4">
              <label>Tahun Survei</label>
            </div>
            <div class="col-md-8">
              <select
                v-model="form.tahun_survey"
                class="form-select"
                :class="{
                  ' is-invalid': formValidate.tahun_survey === false,
                  ' is-valid': formValidate.tahun_survey === true,
                }"
              >
                <option value="">Silahkan Pilih</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6" style="margin-bottom: -30px">
              <a href="javascript:;" class="cards no-link" @click="openForm(1)">
                <div class="card-down bg-danger"></div>
                <div class="card-up">
                  <div class="card-content">
                    <p>Formulir 1</p>
                    <span>Perbaikan Bangunan Irigasi</span>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-6" style="margin-bottom: -30px">
              <a href="javascript:;" class="cards no-link" @click="openForm(2)">
                <div class="card-down bg-warning"></div>
                <div class="card-up">
                  <div class="card-content">
                    <p>Formulir 2</p>
                    <span>Perbaikan Saluran</span>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-6" style="margin-bottom: -30px">
              <a href="javascript:;" class="cards no-link" @click="openForm(3)">
                <div class="card-down bg-info"></div>
                <div class="card-up">
                  <div class="card-content">
                    <p>Formulir 3</p>
                    <span>Jalan Inspeksi</span>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-6" style="margin-bottom: -30px">
              <a href="javascript:;" class="cards no-link" @click="openForm(4)">
                <div class="card-down bg-primary"></div>
                <div class="card-up">
                  <div class="card-content">
                    <p>Formulir 4</p>
                    <span>Galian Endapan</span>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-6" style="margin-bottom: -30px">
              <a href="javascript:;" class="cards no-link" @click="openForm(5)">
                <div class="card-down bg-success"></div>
                <div class="card-up">
                  <div class="card-content">
                    <p>Formulir 5</p>
                    <span>Luas Tanam</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      form: {
        nama_surveyor: "",
        daerah_irigasi: "",
        nama_daerah_irigasi: "",
        tahun_survey: "",
      },
      formValidate: {
        nama_surveyor: "",
        daerah_irigasi: "",
        tahun_survey: "",
      },
      daerahIrigasi: [],
    };
  },
  created() {
    this.loadFormFromLocalStorage();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (navigator.onLine) {
        this.fetchOnline();
      } else {
        console.log("mengambil data offline");
        const daerahIrigasi = localStorage.getItem("daerahIrigasi");
        if (daerahIrigasi) {
          this.daerahIrigasi = JSON.parse(daerahIrigasi);
          console.log("sukses mengambil data offline");
        }
      }
    },
    fetchOnline() {
      axios
        .get("/v1/daerah_irigasi/")
        .then((response) => {
          this.daerahIrigasi = response.data.data;
          localStorage.setItem(
            "daerahIrigasi",
            JSON.stringify(response.data.data)
          );
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    handleChange(event) {
      this.form.daerah_irigasi = event.target.value;
      const selectedOption = event.target.options[event.target.selectedIndex];
      const key = selectedOption.getAttribute("data-key");
      this.form.nama_daerah_irigasi = key;
    },
    updatemenu() {
      if (document.getElementById("responsive-menu").checked == true) {
        document.getElementById("menu").style.borderBottomRightRadius = "0";
        document.getElementById("menu").style.borderBottomLeftRadius = "0";
      } else {
        document.getElementById("menu").style.borderRadius = "10px";
      }
    },
    openForm(param) {
      // Membuat objek untuk memetakan param ke route
      const routeMap = {
        1: "formulir-1",
        2: "formulir-2",
        3: "formulir-3",
        4: "formulir-4",
        5: "formulir-5",
      };

      // Memeriksa dan mengatur formValidate sesuai kondisi
      this.formValidate.nama_surveyor = this.form.nama_surveyor !== "";
      this.formValidate.daerah_irigasi = this.form.daerah_irigasi !== "";
      this.formValidate.tahun_survey = this.form.tahun_survey !== "";

      // Mengganti route jika semua validasi benar
      if (
        this.formValidate.nama_surveyor &&
        this.formValidate.daerah_irigasi &&
        this.formValidate.tahun_survey
      ) {
        if (routeMap[param]) {
          const formsData = {
            nama_surveyor: this.form.nama_surveyor,
            daerah_irigasi: this.form.daerah_irigasi,
            nama_daerah_irigasi: this.form.nama_daerah_irigasi,
            tahun_survey: this.form.tahun_survey,
          };

          localStorage.setItem("formsData", JSON.stringify(formsData));
          this.$router.push(routeMap[param]);
        }
      }
    },

    loadFormFromLocalStorage() {
      const savedFormsData = localStorage.getItem("formsData");

      if (savedFormsData) {
        const formsData = JSON.parse(savedFormsData);
        this.form.nama_surveyor = formsData.nama_surveyor;
        this.form.daerah_irigasi = formsData.daerah_irigasi;
        this.form.nama_daerah_irigasi = formsData.nama_daerah_irigasi;
        this.form.tahun_survey = formsData.tahun_survey;
      }
    },
  },
};
</script>

<style scoped>
.no-link {
  text-decoration: none;
}
.box {
  border-top: solid 5px #2968a0;
  border-bottom: solid 5px #0099ff;
}
.cards {
  padding: 10px;
}

.card-up {
  height: 150px;
  border-radius: 20px;
  background-color: #f0f8ff;
  color: #000;
  margin-left: 20px;
  margin-top: -135px;
  padding: 30px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  align-content: center;
  align-items: center;
}
.card-up:hover {
  background-color: #0099ff;
  color: #fff;
  transform: translateY(-10%);
  transition: transform 0.3s, opacity 0.3s, filter 0.3s;
}
.card-up p {
  font-size: 20px;
  font-weight: bold;
}
.card-up span {
  font-size: 16px;
}
.card-down {
  height: 150px;
  border-radius: 20px;
  background-color: #2968a0;
  margin: 0px 20px 0px 0px;
  padding: 30px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  align-content: center;
  align-items: center;
}
</style>
