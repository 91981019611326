<template>
  <div>
    <div>
      <div class="header align-items-center justify-content-center">
        <div class="text-center p-4">
          <img src="../assets/logo-sulteng.png" width="100px" />
          <h2>Surveillance Irigasi</h2>
          <h5>CIKASDA SULTENG</h5>
        </div>
      </div>
      <div class="header-second"></div>
    </div>
    <div class="p-4" style="margin-bottom: 80px">
      <div
        class="row align-items-center justify-content-center"
        style="margin-top: -180px"
      >
        <div class="col-md-5 bg-white p-4 rounded-5 shadow-lg box">
          <h4 class="mb-4 text-center">Login</h4>
          <form @submit.prevent="login" method="POST">
            <div class="mb-3">
              <label class="form-label">Email</label>
              <input v-model="username" type="email" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Password</label>
              <input v-model="password" type="password" class="form-control" />
            </div>
            <div
              v-if="showAlert"
              class="alert alert-dismissible fade show"
              :class="{
                '  alert-success': alert == 'success',
                '  alert-danger': alert == 'error',
              }"
              role="alert"
            >
              <span>{{ message }}</span>
            </div>
            <div class="d-grid gap-2 mt-3">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="this.loading"
              >
                <!-- <i v-if="loading" class="fas fa-circle-notch fa-spin"></i> -->
                <font-awesome-icon
                  v-if="loading"
                  icon="circle-notch"
                  :spin="true"
                />
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="footer fixed-bottom">
      &copy; Surveillance Irigasi | Dinas Cipta Karya dan Sumber Daya Air
      Provinsi Sulawesi Tengah.
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      alert: "",
      showAlert: false,
      remember_me: false,
    };
  },
  methods: {
    login() {
      this.loading = true;
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("remember", this.remember_me);

      axios
        .post("/v1/auth/login/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          if (response.data.status == true) {
            this.loading = false;
            this.showAlert = true;
            this.alert = "success";
            this.message = "Selamat Datang " + response.data.nama;
            setTimeout(() => {
              const surveillanceData = {
                id: response.data.id,
                nama: response.data.nama,
                email: response.data.email,
                level: response.data.level,
                token: response.data.token,
              };
              localStorage.setItem(
                "surveillanceData",
                JSON.stringify(surveillanceData)
              );
              this.showAlert = false;
              this.$router.push("/dashboard");
            }, 2000);
          } else {
            this.loading = false;
            this.alert = "error";
            this.showAlert = true;
            this.message = response.data.message;
            setTimeout(() => {
              this.alert = "";
              this.showAlert = false;
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>

<style scoped>
.no-link {
  text-decoration: none;
}
.box {
  border-top: solid 5px #2968a0;
  border-bottom: solid 5px #0099ff;
}
.header {
  background: #0099ff;
  background: linear-gradient(to top, #0099ff 0%, #2968a0 100%);
  color: #fff;
  font-weight: bolder;
}
.header-second {
  height: 150px;
  background-color: #0099ff;
  border-radius: 0px 0px 50% 50%;
}
.footer {
  width: 100%;
  /* height: 80px; */
  background-color: #0099ff;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center; /* Mengatur teks secara vertikal di tengah */
  justify-content: center; /* Mengatur teks secara horizontal di tengah */
  font-weight: light;
  font-size: 14px;
  padding: 20px;
}
</style>