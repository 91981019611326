<template>
  <div class="footer">
    &copy; Surveillance Irigasi | Dinas Cipta Karya dan Sumber Daya Air Provinsi
    Sulawesi Tengah.
  </div>
</template>
<script>
export default {
  name: "FooterPage",
};
</script>
<style scoped>
.footer {
  /* height: 80px; */
  background-color: #0099ff;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center; /* Mengatur teks secara vertikal di tengah */
  justify-content: center; /* Mengatur teks secara horizontal di tengah */
  font-weight: light;
  font-size: 14px;
  padding: 20px;
}
</style>