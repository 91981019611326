var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"layoutSidenav_nav"}},[_c('nav',{staticClass:"sb-sidenav accordion sb-sidenav-light",attrs:{"id":"sidenavAccordion"}},[_c('div',{staticClass:"sb-sidenav-menu"},[_c('div',{staticClass:"nav"},[_c('div',{staticClass:"sb-sidenav-menu-heading"},[_vm._v("Menu")]),_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'dashboard',
          },attrs:{"to":"/dashboard"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('font-awesome-icon',{attrs:{"icon":"home"}})],1),_vm._v(" Dashboard ")]),_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'bangunan-irigasi',
          },attrs:{"to":"/bangunan-irigasi"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"icon-bendungan"})]),_vm._v(" Bangunan Irigasi ")]),_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'saluran',
          },attrs:{"to":"/saluran"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"icon-saluran"})]),_vm._v(" Saluran ")]),_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'jalan-inspeksi',
          },attrs:{"to":"/jalan-inspeksi"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"icon-jalan"})]),_vm._v(" Jalan Inspeksi ")]),_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'galian-endapan',
          },attrs:{"to":"/galian-endapan"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"icon-galian"})]),_vm._v(" Galian Endapan ")]),_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'tanaman',
          },attrs:{"to":"/tanaman"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"icon-galian"})]),_vm._v(" Tanaman ")]),_c('a',{staticClass:"nav-link collapsed",attrs:{"href":"javascript:;","data-bs-toggle":"collapse","data-bs-target":"#pagesCollapseMaster","aria-expanded":"true","aria-controls":"pagesCollapseMaster"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('font-awesome-icon',{attrs:{"icon":"database"}})],1),_vm._v(" Master "),_c('div',{staticClass:"sb-sidenav-collapse-arrow"},[_c('font-awesome-icon',{attrs:{"icon":"angle-down"}})],1)]),_c('div',{staticClass:"collapse",class:{ show: _vm.isMasterMenuOpen },attrs:{"id":"pagesCollapseMaster","aria-labelledby":"headingOne","data-bs-parent":"#sidenavAccordionPages"}},[_c('nav',{staticClass:"sb-sidenav-menu-nested nav"},[_c('router-link',{staticClass:"nav-link",class:{
                ' active': _vm.link == 'master-daerah-irigasi',
              },attrs:{"to":"/master-daerah-irigasi"}},[_vm._v(" Daerah Irigasi ")]),_c('router-link',{staticClass:"nav-link",class:{
                ' active': _vm.link == 'master-bangunan',
              },attrs:{"to":"/master-bangunan"}},[_vm._v(" Bangunan ")]),_c('router-link',{staticClass:"nav-link",class:{
                ' active': _vm.link == 'master-saluran',
              },attrs:{"to":"/master-saluran"}},[_vm._v(" Saluran ")]),_c('router-link',{staticClass:"nav-link",class:{
                ' active': _vm.link == 'master-ruas',
              },attrs:{"to":"/master-ruas"}},[_vm._v(" Ruas ")])],1)]),(_vm.userLevel == 'admin')?_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'pengguna',
          },attrs:{"to":"/pengguna"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('font-awesome-icon',{attrs:{"icon":"users"}})],1),_vm._v(" Pengguna ")]):_vm._e()],1)]),_c('div',{staticClass:"sb-sidenav-footer"},[_c('div',{staticClass:"small"},[_vm._v("Login as :")]),_c('span',[_vm._v(_vm._s(_vm.userEmail))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }