<template>
  <nav class="sb-topnav navbar navbar-expand navbar-dark bg-primary">
    <router-link class="navbar-brand ps-3" to="/dashboard">
      Surveillance Irigasi
    </router-link>
    <button
      class="btn btn-link btn-sm order-1 order-lg-0"
      id="sidebarToggle"
      @click="toggleSidebar"
    >
      <font-awesome-icon icon="bars" />
    </button>
    <ul class="navbar-nav ms-auto me-0 me-md-3 my-2 my-md-0">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          id="navbarDropdown"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <font-awesome-icon icon="user" />
        </a>
        <ul
          class="dropdown-menu dropdown-menu-end"
          aria-labelledby="navbarDropdown"
        >
          <li>
            <router-link
              :to="'/pengguna/update/' + this.id"
              class="dropdown-item"
            >
              Profil Saya
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/pengguna/password/' + this.id"
              class="dropdown-item"
            >
              Ganti Password
            </router-link>
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <a href="javascript:;" class="dropdown-item" @click="logout">
              Logout
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
<script>
import Swal from "sweetalert2";

export default {
  name: "NavBar",
  data() {
    return {
      id: "",
    };
  },
  created() {
    const user = localStorage.getItem("surveillanceData");
    if (user) {
      this.user = JSON.parse(user);
      this.id = this.user.id;
    }
  },
  methods: {
    toggleSidebar() {
      document.body.classList.toggle("sb-sidenav-toggled");
      localStorage.setItem(
        "sb|sidebar-toggle",
        document.body.classList.contains("sb-sidenav-toggled")
      );
    },
    logout() {
      Swal.fire({
        title: "Apakah Anda Akan Logout ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Logout!",
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("surveillanceData");
          if (this.$route.path !== "/login") {
            this.$router.push("/login");
          }
        }
      });
    },
  },
};
</script>